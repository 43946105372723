/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import combination from "../../../images/Combinatorics/combinations.webp";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    em: "em",
    ol: "ol",
    li: "li",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "combination",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#combination",
    "aria-label": "combination permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Combination"), "\n", React.createElement(_components.p, null, "and program to find the Combination in Rust"), "\n", React.createElement(_components.h2, {
    id: "what-is-combination",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-combination",
    "aria-label": "what is combination permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Combination"), "\n", React.createElement(_components.p, null, "Combination is the number of ways in which we can ", React.createElement(_components.strong, null, "choose"), " some objects from the given set of objects.\nIn combination, the order in which things are arranged ", React.createElement(_components.strong, null, "do not"), " matter, unlike in permutation."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "For example :"), " The Combinations of word RUST of length 3 are 4 => RUS, RUT, RST, UST."), "\n", React.createElement(_components.p, null, "In this article, we will use standard reference : the number of total objects in the set are denoted by ", React.createElement(_components.strong, null, "n"), " and the number of items chosen at a time are denoted by ", React.createElement(_components.strong, null, "r"), "."), "\n", React.createElement(_components.p, null, "So, total number of ways of choosing ", React.createElement(_components.em, null, "r"), " items from ", React.createElement(_components.em, null, "n"), " items are represented as ", React.createElement(_components.strong, null, React.createElement("sup", null, "n"), "C", React.createElement("sub", null, "r")), ". It will also be written as ", React.createElement(_components.strong, null, "C(n, r)")), "\n", "\n", React.createElement("div", {
    style: {
      textAlign: 'center'
    }
  }, React.createElement("img", {
    src: combination,
    width: "100%",
    alt: "Combinations of RUST"
  })), "\n", React.createElement(_components.h2, {
    id: "combination-formulae",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#combination-formulae",
    "aria-label": "combination formulae permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Combination formulae"), "\n", React.createElement(_components.p, null, "Here are some frequently used formulae for Combinations."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement("sup", null, "n"), "C", React.createElement("sub", null, "r")), " = n ! / ( (n-r)! × r! )"), "\n", React.createElement(_components.li, null, React.createElement("sup", null, "n"), "C", React.createElement("sub", null, "0"), " + ", React.createElement("sup", null, "n"), "C", React.createElement("sub", null, "1"), " + ", React.createElement("sup", null, "n"), "C", React.createElement("sub", null, "2"), " ..... + ", React.createElement("sup", null, "n"), "C", React.createElement("sub", null, "n"), " = ", React.createElement(_components.strong, null, "2", React.createElement("sup", null, "n"))), "\n"), "\n", React.createElement(_components.h2, {
    id: "program-to-find-combination",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#program-to-find-combination",
    "aria-label": "program to find combination permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Program to find Combination"), "\n", React.createElement(_components.p, null, "Now, let us write a program in Rust Language to find the number of permutations when we are given ", React.createElement(_components.em, null, "n"), " distinct objects, and we can arrange ", React.createElement(_components.em, null, "r"), " at a time."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn combination(n: usize, r: usize) -> usize{\n    // nCr = n! / (r! * (n-r)!)\n    return factorial(n)/(factorial(r) * factorial(n-r));\n}\n")), "\n", React.createElement(_components.p, null, "With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn combination(n: usize, r: usize) -> usize{\n    // nCr = n! / (r! * (n-r)!)\n    return factorial(n)/(factorial(r) * factorial(n-r));\n}\n\n// Driver code\n\nuse std::io::stdin;\nfn take_int() -> usize {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\nfn factorial(number : usize) -> usize{\n    let mut factorial : usize = 1;\n    for i in 1..(number+1) { factorial*=i; }\n    return factorial;\n}\n\npub fn main() {\n    let n = take_int();\n    let r = take_int();\n    println!(\"{}\", combination(n, r));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "6 ", React.createElement("br"), "\n4"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "15"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Combination is the number of ways in which we can ", React.createElement(_components.strong, null, "choose"), " some objects from the given set of objects.\nIn this article, we saw the frequently used formulae of combinations for given n and r and also made a program to find number of combinations in Rust."), "\n", React.createElement(_components.p, null, "Here is the function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn combination(n: usize, r: usize) -> usize{\n    return factorial(n)/(factorial(r) * factorial(n-r));\n}\n\nfn factorial(number : usize) -> usize{\n    let mut factorial : usize = 1;\n    for i in 1..(number+1) { factorial*=i; }\n    return factorial;\n}\n\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Combination - Rust Programming",
    description: "Combination is the number of ways in which we can choose some objects from the given set of objects. We will see frequently used formulae of combinations and make a program to find number of combinations in Rust."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
